import Carousel from "../components/Carousel";
import Contact from "../components/Contact";
import Feedbacks from "../components/Feedbacks";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IdeaSectionAbout from "../section/IdeaSectionAbout";
import WebDesignHero from "../section/WebDesignHero";
import React from "react";

function WebsiteDesign() {
  return (
    <div className="relative z-0 bg-[#060816]">
      <div className="bg-center bg-no-repeat bg-cover bg-hero-pattern">
        <Navbar />

        {/** About Section */}
        <WebDesignHero />
      </div>

      {/**Slider logos company */}
      <Carousel />

      {/**idea section and btns */}
      <IdeaSectionAbout />

      {/** Testimonials */}
      <div className="-mt-36">
        <Feedbacks />
      </div>
      {/** contact section */}
      <div className="relative z-0">
        <Contact />
      
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteDesign;

import React from "react";
import { styles } from "../styles";
import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import { headerImageHome, webconcepts01 } from "../assets";
import { motion } from "framer-motion";
import "../index.css";
import axios from "axios";
import Lottie from "lottie-react";
import { phoneIconjn, calljsicon,emailIconjn} from "../assets";
import { slideIn } from "../utils/motion";

const Hero = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tevtjug', 'template_4ysk27t', form.current, '9wRe4B2w_RrusdCmg')
      .then((result) => {
          console.log(result.text);
          window.open("/thank-you", "_blank");
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <section className="relative w-full h-screen ">
      <div
        className={`${styles.paddingX} absolute inset-0 top-[120px] pt-10   2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row flex flex-col items-start justify-center gap-5`}
      >
        <div className="flex flex-row gap-4">
          {/** inside columns */}
          <div>
                <div className="flex flex-col items-center justify-center mt-5">
                    {/** dot circle line */}
                    <div className="w-5 h-5 rounded-full bg-[#915eff]" />
                    <div className="w-1 h-40 sm:h-80 violet-gradient" />
              </div>
          </div>
          <div>
            <h1
              className={` font-black  lg:text-[50px] sm:text-[40px] xs:text-[50px] text-[40px] lg:leading-[40px] text-white pt-20`}
            >
              Welcome to{" "}
              <span className="text-[#915eff]">The Designs Inc.</span>
            </h1>
            <p className={`${styles.heroSubText} mt-2`}>
              We create stunning website and logo design, UI/UX and Web{" "}
              <br className="hidden sm:block" />
              application development in arbitrary language also we create app{" "}
              <br className="hidden sm:block" />
              with react, Mobile apps Native apps.
            </p>
            {/** call to actions start */}
            <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>

            {/** call to actions end */}
            
            <span>
                    <Lottie animationData={webconcepts01} className="max-w-lg"  />
                  </span>
          </div>
        </div>
        <div>
          <div
            variants={slideIn("left", "tween", 0, 2, 1)}
            className="px-20 py-5 bg-black-100 rounded-2xl"
          >
           
            <h3 className="text-white font-black md:text-[40px] sm:text-[20px] xs:text-[20px] text-[40px]">Let's Get Started</h3>
            <form  ref={form} onSubmit={sendEmail} className="flex flex-col gap-8 mt-12">
        <label className="flex flex-col">
          
          <input
          type='text'
          id="user_name"
          name="user_name"
          placeholder="Adam Langford"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        { /** email */}

        <label className="flex flex-col">
          
          <input
          id="user_email"
          type='text'
          name="user_email"
          placeholder="info@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        <label className="flex flex-col">
          
          <input
          id="user_phone"
          type='tel'
          name="user_phone"
          placeholder="(646) 847-7924"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        { /** message */}

        <label className="flex flex-col">
          
          <textarea
          id="message"
          name="message"
          rows="4"
          placeholder="What's your query?"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="px-6 py-4 font-medium text-white border-none rounded-lg outline-none bg-tertiary placeholder:text-tertiary"/>
        </label>

        <button
        type="submit"
        className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"        
        >Submit</button>


      </form>
          </div>
        </div>
      </div>

      <div></div>
      {/** Old 3d */}

      {/** Old 3d */}

      <div className="absolute left-auto right-auto flex items-center justify-center w-full xs:bottom-10 -bottom-20">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-2 border-secondary flex justify-center items-center p-2">
            <motion.dev
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 mb-1 rounded-full bg-secondary"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;

import { useState, useEffect } from "react";
import { gql, GraphQLClient } from "graphql-request";
import { sectionWrapper } from "../hoc";

import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../utils/motion";
import { services } from "../constant";

const MY_QUERY = gql`
  query MyQuery {
    allPackages(where: {selectCategory_in: ComboPackage}) {
      id
      packagesDescriptions {
        html
      }
      packageTitle
      packagePrice
      selectCategory
    }
  }
`;

function ComboPackages() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = new GraphQLClient(
      "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clgchterx4bkb01ui1t3baxyk/master"
    );
    const fetchData = async () => {
      const data = await client.request(MY_QUERY);
      setData(data);
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="flex flex-wrap items-start justify-center gap-x-4">
      {data.allPackages.map((post) => (
        <div className="xl:w-1/4 sm:w-1/3 md:w-1/3">
          <div className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card m-5">
            <div
              key={post.id}
              className="mt-0 bg-tertiary rounded-[20px]   p-5  px-4 justify-center m-0 py-10 "
            >
              <h2 className="font-semibold text-[26px] text-center">{post.packageTitle}</h2>
              <p className="text-[#b65ef4] text-center font-bold text-[36px]">
                ${post.packagePrice}
              </p>
              <div className="items-center justify-center">
              <div className=" h-52 overflow-scroll text-[15px] bg-[#0b081c] rounded-md p-3 ml-auto mr-auto" dangerouslySetInnerHTML={{__html:post.packagesDescriptions.html}} />

                <div className="flex flex-row items-center justify-center gap-6">

                  <div>
                  <button
                    type="submit"
                    className="bg-tertiary w-fit py-3 px-2 outline-none border-none text-[12px]  shadow-md shadow-primary rounded-xl "
                  >
                    Get Qoute
                  </button>
                  </div>

                  <div>

                  <button
                    type="submit"
                    className="bg-tertiary w-fit py-3 px-2 outline-none border-none text-[12px] shadow-md shadow-primary rounded-xl "
                  >
                    (718) 785-3776
                  </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default sectionWrapper(ComboPackages);

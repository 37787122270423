import logo from './logo.svg';
import './App.css';
import './index.css'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from "./pages/index"
import AboutUs from "./pages/about"
import Contact from "./pages/contact"
import WebsiteDesign from "./pages/webdesign"
import DigitalMarketing from "./pages/digital-marketing"
import ThreeDMotion from "./pages/3d-motion-graphic"
import Ecommerce from "./pages/e-commerce-services"
import MobileApps from "./pages/mobile-app-services"
import ModernLogoDesign from "./pages/modern-logo-design"
import WebContentWriting from "./pages/web-content-writing"
import SocialMedia from "./pages/social-media-marketing"
import SearchEngine from "./pages/searchengine"
import Portfolio from "./pages/portfolio"
import Packages from "./pages/packages"
import Test from "./pages/test"
import ForZerFor from './pages/404';
import TermsOfUse from './pages/terms-of-use';
import PrivacyPolicy from './pages/privacy-policy';
import ThankYou from './pages/thank-you';



function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="*" element={<ForZerFor />} />
      <Route path="/about" element={<AboutUs />} exact/>
      <Route path="/contact" element={<Contact />} exact/>
      <Route path="/webdesign" element={<WebsiteDesign />} exact/>
      <Route path="/digital-marketing" element={<DigitalMarketing />} exact/>
      <Route path="/3d-motion-graphic" element={<ThreeDMotion />} exact/>
      <Route path="/e-commerce-services" element={<Ecommerce />} exact/>
      <Route path="/mobile-app-services" element={<MobileApps />} exact/>
      <Route path="/modern-logo-design" element={<ModernLogoDesign />} exact/>
      <Route path="/web-content-writing" element={<WebContentWriting />} exact/>
      <Route path="/social-media-marketing" element={<SocialMedia />} exact/>
      <Route path="/search-engine-optimization" element={<SearchEngine />} exact/>
      <Route path="/portfolio" element={<Portfolio />} exact/>
      <Route path="/packages" element={<Packages />} exact/>
      <Route path="/test" element={<Test />} exact/>
      <Route path="/terms-of-use" element={<TermsOfUse />} exact/>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} exact/>
      <Route path="/thank-you" element={<ThankYou />} exact/>
   
  
   
      
    </Routes>

    
    </BrowserRouter>
  );
}

export default App;

import { close, logo, menu } from '../assets'
import { navLinks } from '../constant'

import React, { useState } from 'react'
import { styles } from '../styles'



;
import { Link } from 'react-router-dom';

function Navbar() {


const [isOpen, setIsOpen] = useState(false);
const [Active, SetActive] = useState("");
const [toggle, setToggle] = useState(false);

const toggleNav = () => {
  setIsOpen(!isOpen);
}
  return (
    <nav className={`${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-20 bg-[#060816]`}>
      <div className="flex items-center justify-between w-full mx-auto max-w-7xl">
        
          
            <a
              href="/"
              className="flex-shrink-0 text-xl font-bold text-white uppercase"
            >
            <img src={logo} alt="logo" className="object-contain w-50 h-50" />
            </a>
          
          <div className="hidden md:block">
            <div className="flex-row hidden gap-10 list-none sm:flex">
              {navLinks.map((item) =>
                item.dropdown ? (
                  <div key={item.id} className="relative">
                    <button
                      onClick={toggleNav}
                      className={`${
                        Active === item.title ? "text-white" : "text-secondary"
                      } hover:text-white text-[18px] font-medium cursor-pointer`}
                    >
                      {item.title}
                     
                    </button>
                    <div
                      className={`${
                        isOpen ? "block" : "hidden"
                      } absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10`}
                    >
                      {item.submenu.map((subitem) => (
                        <a
                          key={subitem.title}
                          href={`/${subitem.url}`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                         
                          {subitem.title}
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <a
                    key={item.title}
                    href={`/${item.to}`}

                    className={`${
                Active === item.title ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
                  >
                   {item.title}
                  </a>
                )
              )}
            </div>
          </div>



           {/** Mobile Devices */}
        <div className="flex items-center justify-end flex-1 sm:hidden">
          <img
            src={toggle ? close : menu}
            className="cursor-pointer w-9 h-9"
            alt="icon-menu"
            onClick={() => setToggle(!toggle)}
          />
          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="flex flex-col items-start justify-end gap-4 list-none">
            {navLinks.map((item) =>
                item.dropdown ? (
                  <div key={item.id} className="relative">
                    <button
                      onClick={toggleNav}
                      className={`${
                        Active === item.title ? "text-white" : "text-secondary"
                      } hover:text-white text-[18px] font-medium cursor-pointer`}
                    >
                      {item.title}
                     
                    </button>
                    <div
                      className={`${
                        isOpen ? "block" : "hidden"
                      } absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10`}
                    >
                      {item.submenu.map((subitem) => (
                        <Link
                          key={subitem.title}
                          href={`/${subitem.url}`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                         
                          {subitem.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    key={item.title}
                    href={`/${item.id}`}

                    className={`${
                Active === item.title ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
                  >
                   {item.title}
                  </Link>
                )
              )}
            </ul>
          </div>
        </div>
       
      </div>
    </nav>
  )
}

export default Navbar
import React from "react";
import { styles } from "../styles";
import { logo, thumtackLogo, trustpilot } from "../assets";
import { sectionWrapper } from "../hoc";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiFillLinkedin,
} from "react-icons/ai";

const fbLink = () => {
  window.open("https://www.facebook.com/thedesignsinc/", "_blank");
};

const instaLink = () => {
  window.open("https://www.instagram.com/thedezynsinc/", "_blank");
};

const youtubeLink = () => {
  window.open("https://www.youtube.com/@thedezynsinc.5315", "_blank");
};

const linkedinLink = () => {
  window.open("https://www.linkedin.com/in/the-designs-inc-4b4670218", "_blank");
};

function Footer() {
  return (
    <>
      {/* Start of logodesignsinc Zendesk Widget script */}
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=b685adc2-c6d4-4cf3-8d5c-9a7961770b8d"
      ></script>
      {/* End of logodesignsinc Zendesk Widget script */}
      <div className="flex flex-col items-start justify-between w-full px-10 py-5 mx-auto bg-black-100 xl:flex-row md:flex-row sm:flex-row">
        <div className="w-full xlg:w-1/3 lg:w-1/3 md:w-1/3 sm:w-1/3 ">
          <img src={logo} className="w-30 h-30 " alt="logo" />
          <p className="mt-10">
            TheDesignsInc is a Design Agency that specializes in providing logo
            and website design services. They help businesses and individuals
            create unique and effective branding by creating custom logos that
            represent their values and mission.
          </p>
          <div className="flex flex-1 gap-3">
            <div>
              <AiFillFacebook
                onClick={fbLink}
                className="h-8 cursor-pointer w-7"
              />
            </div>
            <div>
              <AiFillInstagram
                onClick={instaLink}
                className="h-8 cursor-pointer w-7"
              />
            </div>
            <div>
              <AiFillLinkedin
                onClick={linkedinLink}
                className="h-8 cursor-pointer w-7"
              />
            </div>
            <div>
              <AiFillYoutube
                onClick={youtubeLink}
                className="h-8 cursor-pointer w-7"
              />
            </div>
          </div>

          <div className="flex items-start flex-1 gap-3 py-4">
            <div>
              <img
                src={thumtackLogo}
                className="w-24 h-14"
                alt="TheDesignsinc Trustpilot"
              />
            </div>
            <div>
              <img
                src={trustpilot}
                className="w-24 h-14"
                alt="TheDesignsinc Trustpilot"
              />
            </div>
          </div>
        </div>
        <div className="">
          <h2 className="font-bold text-white text-[19px] py-4">
            
              <div class=" w-full rounded-md bg-gradient-to-r from-[#00cea8] via-[#bf61ff] to-[#00cea8] pb-[2px]">
                <div class="flex h-full w-full items-center justify-start px-2 bg-primary back">
                  <h1 class=" font-black text-white">Explore Services</h1>
                </div>
              </div>
           
          </h2>
          <ul>
            <li>
              <a href="/webdesign">Website Design</a>
            </li>
            <li>
              <a href="/e-commerce-services">Ecommerce</a>
            </li>
            <li>
              <a href="/digital-marketing">Digital Marketing</a>
            </li>
            <li>
              <a href="/3d-motion-graphic">3D Motion Graphics</a>
            </li>
            <li>
              <a href="/mobile-app-services">Mobile Apps</a>
            </li>
            <li>
              <a href="/modern-logo-design">Modern Logo Design</a>
            </li>
            <li>
              <a href="/web-content-writing">Web Content Writing</a>
            </li>
            <li>
              <a href="/social-media-marketing">Social Media Marketing</a>
            </li>
          </ul>
        </div>

        <div className="">
          

          <h2 className="font-bold text-white text-[19px] py-4">
            
          <div class=" w-full rounded-md bg-gradient-to-r from-[#00cea8] via-[#bf61ff] to-[#00cea8] pb-[2px]">
                <div class="flex h-full w-full items-center justify-start px-2 bg-primary back">
                <h1 class=" font-black text-white">Useful Links</h1>
              </div>
            </div>
         
        </h2>
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/portfolio">Portfolio</a>
            </li>
            <li>
              <a href="/e-commerce-services">Ecommerce</a>
            </li>
            <li>
              <a href="/webdesign">Website Design</a>
            </li>
            <li>
              <a href="/digital-marketing">Digital Marketing</a>
            </li>
          </ul>
        </div>
        <div className="">
         

          <h2 className="font-bold text-white text-[19px] py-4">
            
          <div class=" w-full rounded-md bg-gradient-to-r from-[#00cea8] via-[#bf61ff] to-[#00cea8] pb-[2px]">
                <div class="flex h-full w-full items-center justify-start px-2 bg-primary back">
                <h1 class=" font-black text-white">Legal</h1>
              </div>
            </div>
         
        </h2>


          <ul>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/terms-of-use">Terms Of Use</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="">
          


          <h2 className="font-bold text-white text-[19px] py-4">
            
          <div class=" w-full rounded-md bg-gradient-to-r from-[#00cea8] via-[#bf61ff] to-[#00cea8] pb-[2px]">
                <div class="flex h-full w-full items-center justify-start px-2 bg-primary back">
                <h1 class=" font-black text-white">Contact Info</h1>
              </div>
            </div>
         
        </h2>


          <ul>
            <li>info@thedesignsinc.com</li>
            <div className="flex flex-1 gap-3">
              <li>(718) 785-3776</li>
              <li>&</li>
              <li>(929) 277-4891</li>
            </div>

            <li>Flushing, NewYork</li>
          </ul>
        </div>
      </div>

      <div className="items-center text-center ">
        <p className="text-gray-400">
          CopyRight 2023 - Legal Name: Dezyn Inc HandCoded By: TheDesignsInc.
        </p>
      </div>
    </>
  );
}

export default Footer;

import React from "react";

import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { styles } from "./../styles";
import { services } from "../constant";
import { fadeIn, textVariant } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aboutCompAnimation } from "../assets";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="xs:w-[250px] w-full flex-col ">
      <div className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card">
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className="object-contain w-16 h-16" />
          <h3 className="text-white text-[20px] font-bold text-center">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <>
      <div className="flex-col items-center justify-between py-20 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row">
        <div>
          <div>
            <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
              Introduction
            </p>
            <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">
              Overview
            </h2>
          </div>

          <p className="mt-4 text-secondary text-[17px] 2xl:max-w-3xl xl:max-w-3xl sm:max-w-xl w-full leading-[30px]">
            At TheDesignsInc, we're passionate about creating compelling digital
            experiences that engage and inspire. Our design agency specializes
            in website, mobile app, and logo design services, working closely
            with clients to understand their unique needs and goals. Whether
            you're launching a new brand, refreshing an existing website, or
            building an app from scratch, our team of experts is here to help
            you every step of the way. We pride ourselves on delivering
            customized, high-quality designs that make a lasting impression. So
            why settle for ordinary when you can work with TheDesignsInc to
            create something extraordinary? Contact us today to learn more about
            how we can bring your vision to life.
          </p>
        </div>
        <div className="">
        
          <Lottie animationData={aboutCompAnimation} className="w-72 h-72" />
          
        </div>
      </div>

      <div className="flex-col gap-10 mt-20 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
};

export default sectionWrapper(About, "about");

import React from "react";

import { motion } from "framer-motion";

import { styles } from "../styles";
import { services } from "../constant";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import { sectionWrapper } from "../hoc";
import { aiimg } from "../assets";



const ServiceCard = ({ index, title, icon }) => {
    return (
      <div className="xs:w-[250px] w-full ">
        <div
          
          className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card"
        >
          <div
            options={{
              max: 45,
              scale: 1,
              speed: 450,
            }}
            className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
          >
            <img src={icon} alt={title} className="object-contain w-16 h-16" />
            <h3 className="text-white text-[20px] font-bold text-center">
              {title}
            </h3>
          </div>
        </div>
      </div>
    );
  };

function ModerLogoHero() {
  return (
    <>
    <div className="flex flex-row items-center justify-end pt-20 gap-x-28 ">
      <div className="w-1/2">
        <div >
          <p className="sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider">
            DESIGN IS POWERFUL
          </p>
          <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[20px] text-[30px]">
            Your Modern Logo Design Doesn’t Have To Be Complicated.
          </h2>
        </div>

        <p
          className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
          
        >
          We are fearless about taking on challenges! With collaboration at
          our core, we are ready to work with you to craft winning solutions.
          We excel at listening, and then acting on your needs, to deliver a
          successful project outcome. Our team of experts are equipped with
          the knowledge and experience needed to get the job done, and are
          poised to deliver a responsive website for your business.
        </p>
      </div>
      <div className="w-1/2">
        <div >
          <img
            src={aiimg}
            className="w-500 h-500 "
            alt="img"
          />
        </div>
      </div>
    </div>

    <div className="flex-col gap-10 mt-20 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row">
      {services.map((service, index) => (
        <ServiceCard key={service.title} index={index} {...service} />
      ))}
    </div>
  </>
  )
}

export default sectionWrapper(ModerLogoHero)
import Carousel from '../components/Carousel'
import Contact from '../components/Contact'
import Feedbacks from '../components/Feedbacks'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import EcommerceHero from '../section/EcommerceHero'
import IdeaSectionAbout from '../section/IdeaSectionAbout'
import React from 'react'



function Ecommerce() {
  return (
    <div className="relative z-0 bg-[#060816]">
       <div className="bg-center bg-no-repeat bg-cover bg-hero-pattern">
 
      <Navbar />
      
        {/** About Section */}
       <EcommerceHero />
       </div>
        {/**Slider logos company */}
        <Carousel />

        {/**idea section and btns */}
        <IdeaSectionAbout />

        {/** Testimonials */}
        <div className="-mt-36">
        <Feedbacks  />
        </div>
        {/** contact section */}
        <div className='relative z-0'>
      <Contact />
     
    </div>
      
   

    <Footer />
  </div>
  )
}

export default Ecommerce
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import React from 'react'


import Lottie from "lottie-react";


import { phoneIconjn, calljsicon,emailIconjn} from "../assets";
import { styles } from '../styles'


function ThankYou() {
  return (
    <div className='relative z-0 bg-[#060816]'>

      <Navbar />
     <div className='pt-64'>
       
        <div className="flex flex-col items-center justify-center py-10">
        <div><p className={`${styles.heroHeadText} py-5` }>Thank You For Signing Up!</p></div>
        <div className="flex flex-col gap-4 mt-6 xl:flex xl:flex-row sm:flex sm:flex-row md:flex md:flex-row">
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={emailIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">info@thedesignsinc.com</span>
                </div>
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="px-8 py-3 font-bold border-none shadow-md outline-none bg-tertiary w-fit shadow-primary rounded-xl"
              >
                <div className="flex flex-row justify-center gap-2">
                  <span>
                    <Lottie animationData={phoneIconjn} className="w-9 h-9" />
                  </span>
                  <span className="mt-2">(718) 785-3776</span>
                </div>
              </button>
            </div>
          </div>
        </div>
     </div>
   
    <Footer />

  </div>
  )
}

export default ThankYou


import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import About from '../components/About'
import Experience from '../components/Experience'
import Feedbacks from '../components/Feedbacks'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import HomePortfolio from '../components/HomePortfolio'




export default function Home() {
  return (
    <div className='relative z-0 bg-primary'>
     <div className='bg-center bg-no-repeat bg-cover bg-hero-pattern'>
  <Navbar />
  <Hero  />
</div>
      <About />
      <Experience />
      <HomePortfolio />
      <Feedbacks />
      <div className='relative z-0'>
        <Contact />
        
       
      </div>

      <Footer />

    </div>
  )
}


